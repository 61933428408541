import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import SidebarListItem from 'components/Sidebar/ListItem';
import SidebarBox from 'components/Sidebar/SidebarBox';

import { isLastItem } from 'lib/array';
import { toArticleImageAltText } from 'lib/image';
import { toArticleLink } from 'lib/links';

const Reviews = forwardRef(({ reviews = [], ...rest }, ref) => {
  if (reviews.length === 0) {
    return null;
  }

  return (
    <SidebarBox ref={ref} title="Reviews" {...rest}>
      {reviews.map(
        (
          { articleId, articleSlug, articleThumbnail, articleTitle },
          index,
          arr
        ) => (
          <SidebarListItem
            key={articleId}
            image={articleThumbnail}
            imageAlt={toArticleImageAltText(articleTitle)}
            isLastItem={isLastItem(arr, index)}
            slug={toArticleLink(articleSlug)}
            text={articleTitle}
          />
        )
      )}
    </SidebarBox>
  );
});

Reviews.displayName = 'Reviews';

Reviews.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      articleId: PropTypes.number.isRequired,
      articleTitle: PropTypes.string.isRequired,
      articleSlug: PropTypes.string.isRequired,
      articleImage: PropTypes.string.isRequired,
    })
  ),
};

export default Reviews;
