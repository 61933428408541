import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

const ListItem = ({ image, imageAlt = null, slug, text }) => (
  <NextLink href={slug} prefetch={false} passHref>
    <Anchor>
      <div
        className="flex items-center py-4"
        data-test-selector="review-blog-item"
      >
        <Image
          alt={imageAlt || text}
          className="mr-4 rounded-2 object-cover"
          height={64}
          src={image}
          width={64}
        />
        <Text size="size2" bold>
          {text}
        </Text>
      </div>
    </Anchor>
  </NextLink>
);

ListItem.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  isLastItem: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ListItem;
