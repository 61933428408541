import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import Box from 'shopper/components/Box';
import Heading from 'shopper/components/Heading';

import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import placeholder from 'lib/placeholder';
import { articleQueryKeys } from 'lib/queryKeys';
import { stripQs } from 'lib/request';
import { noop } from 'lib/utils';

import {
  getArticlesList,
  getArticlesListByCategory,
  getTagArticlesList,
} from 'services/article';

import ArticlesListItem from './ArticlesListItem';

import BLOG from 'constants/blog';

const Promonews = dynamic(() => import('./Promonews'), { ssr: false });
const ArticlesListLoadMore = dynamic(() => import('./ArticlesListLoadMore'), {
  ssr: false,
});

const ENDPOINTS = {
  [BLOG.ARTICLES.FILTERS.CATEGORY]: ({ slug, ...rest }, opts) =>
    getArticlesListByCategory(
      {
        categorySlug: slug,
        ...rest,
      },
      opts
    ),
  [BLOG.ARTICLES.FILTERS.TAG]: ({ slug, ...rest }, opts) =>
    getTagArticlesList(
      {
        tagSlug: slug,
        ...rest,
      },
      opts
    ),
  general: ({ slug, ...rest }, opts) =>
    getArticlesList(
      {
        categorySlug: slug,
        ...rest,
      },
      opts
    ),
};
const FIRST_ARTICLES_LENGTH = 5;

const ArticlesList = ({
  articlesFilter,
  initialArticlesPage,
  slug,
  title = placeholder('titles.lastArticles'),
  onLoadMore = noop,
}) => {
  const router = useRouter();
  const fetchNextPageRef = useRef(null);
  // This ref is being used as a state that doesn't cause re-renders
  const lastArticleIdRef = useRef(initialArticlesPage.after || null);
  const [isScrollPaginationEnabled, setScrollPaginationEnabled] =
    useState(false);

  const {
    data: { articles, firstArticles },
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: articleQueryKeys.list({ articlesFilter, slug }),
    queryFn: async (after, { signal }) => {
      if (after) {
        setScrollPaginationEnabled(true);
      }

      return ENDPOINTS[articlesFilter ?? 'general'](
        {
          slug,
          after,
          limit: BLOG.ARTICLES.CLIENT_SIDE_LIMIT,
        },
        { signal }
      );
    },
    getNextPageParam: (lastArticles) => {
      lastArticleIdRef.current = lastArticles?.after;

      return lastArticles?.after;
    },
    select: (pages) => ({
      firstArticles: pages.slice(0, FIRST_ARTICLES_LENGTH),
      articles: pages.slice(FIRST_ARTICLES_LENGTH),
    }),
    cacheTime: 0,
    staleTime: 0,
    initialData: initialArticlesPage,
    flattenKey: 'articles',
  });

  useIntersectionObserver({
    enabled: hasNextPage && !isFetching && isScrollPaginationEnabled,
    target: fetchNextPageRef,
    rootMargin: '500px',
    threshold: 0,
    onIntersect: fetchNextPage,
  });

  const onLoadMoreArticlesClick = () => {
    onLoadMore();
    fetchNextPage();
    setScrollPaginationEnabled(true);
  };

  if (firstArticles.length === 0) {
    return null;
  }

  return (
    <>
      {lastArticleIdRef.current && (
        <Head>
          <link
            key={`${stripQs(router.asPath)}-next`}
            href={`${stripQs(router.asPath)}?after=${lastArticleIdRef.current}`}
            rel="next"
          />
        </Head>
      )}
      <Box className="mb-6 px-4 pt-6">
        {title && (
          <Heading as="h1" className="mb-7" size="size3">
            {title}
          </Heading>
        )}
        {firstArticles.map((article) => (
          <ArticlesListItem key={article.articleId} {...article} />
        ))}
      </Box>
      <Promonews />
      {articles.length > 0 && (
        <>
          <Box className="px-4 pt-6">
            {articles.map((article) => (
              <ArticlesListItem key={article.articleId} {...article} />
            ))}

            <ArticlesListLoadMore
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              isScrollPaginationEnabled={isScrollPaginationEnabled}
              onLoadMore={onLoadMoreArticlesClick}
            />
          </Box>
          <div ref={fetchNextPageRef} />
        </>
      )}
    </>
  );
};

ArticlesList.propTypes = {
  initialArticlesPage: PropTypes.shape({
    after: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sort: PropTypes.string,
    before: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        articleDescription: PropTypes.string.isRequired,
        articleId: PropTypes.number.isRequired,
        articleImage: PropTypes.string.isRequired,
        articlePublished: PropTypes.string.isRequired,
        articleSlug: PropTypes.string.isRequired,
        articleThumbnail: PropTypes.string.isRequired,
        articleTitle: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        categorySlug: PropTypes.string.isRequired,
        commentCount: PropTypes.number.isRequired,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        articleTags: PropTypes.arrayOf(
          PropTypes.shape({
            tagId: PropTypes.string.isRequired,
            tagName: PropTypes.string.isRequired,
            tagSlug: PropTypes.string.isRequired,
          })
        ),
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  title: PropTypes.string,
  onLoadMore: PropTypes.func,
};

export default ArticlesList;
