import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import Heading from 'shopper/components/Heading';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import { formatDateByDayFullMonthYear } from 'lib/date';
import { getArticleImageLink, toArticleImageAltText } from 'lib/image';
import { toArticleLink } from 'lib/links';
import { noop } from 'lib/utils';

const MainArticlesItem = ({
  articleImage,
  articlePublished,
  articleSlug,
  articleTitle,
  categoryName,
  index,
  userName,
  onClick = noop,
}) => {
  const isFirstArticleItem = index === 0;

  return (
    <div
      className={twJoin(
        'col-span-1 col-start-1 row-auto',
        isFirstArticleItem
          ? 'lg:col-span-1 lg:col-start-1 lg:row-span-2'
          : 'lg:col-span-1 lg:col-start-2 lg:row-span-1',
        index === 2 ? 'lg:row-start-2' : 'lg:row-start-1'
      )}
      data-test-selector="blog-page-item"
    >
      <div
        aria-hidden="true"
        className="relative flex h-full"
        onClick={onClick}
      >
        <div className="absolute top-0 z-10 mx-5 my-4 border-x-0 border-b-3 border-t-0 border-solid border-neutral-high-100">
          <Heading
            as="span"
            className="pb-1 text-neutral-high-100"
            size="size5"
          >
            {categoryName}
          </Heading>
        </div>
        <NextLink href={toArticleLink(articleSlug)} prefetch={false} passHref>
          <Anchor className="size-full">
            <Image
              alt={toArticleImageAltText(articleTitle)}
              className={twJoin(
                'w-full object-cover',
                isFirstArticleItem ? 'lg:h-[504px]' : 'lg:h-[244px]'
              )}
              height={220}
              loading={null}
              priority={index <= 1}
              sizes="(max-width: 768px) 100vw, (max-width: 1280px) 500px, 600px"
              src={getArticleImageLink(articleImage, 600)}
              srcSet={`${getArticleImageLink(
                articleImage,
                500
              )} 500w, ${getArticleImageLink(articleImage, 600)} 600w`}
              width={375}
            />
            <div className="absolute bottom-0 left-0 size-full bg-gradient-to-b from-[rgba(0,0,0,0)] to-[rgba(0,0,0,0.8)]" />
            <div className="absolute bottom-0 z-10 mx-5 my-4">
              <Heading as="span" className="text-neutral-high-100" size="size3">
                {articleTitle}
              </Heading>
              <div className="mt-2 flex items-center md:mt-3">
                <Text
                  className="text-neutral-high-100 dark:text-neutral-high-100"
                  size="size2"
                >
                  {userName}
                </Text>
                <Text className="mx-2 text-neutral-high-100 md:mx-4">•</Text>
                <Text
                  className="text-neutral-high-100 dark:text-neutral-high-100"
                  size="size2"
                >
                  {formatDateByDayFullMonthYear(articlePublished)}
                </Text>
              </div>
            </div>
          </Anchor>
        </NextLink>
      </div>
    </div>
  );
};

MainArticlesItem.propTypes = {
  articleImage: PropTypes.string.isRequired,
  articlePublished: PropTypes.string.isRequired,
  articleSlug: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default memo(MainArticlesItem);
